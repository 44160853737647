define("discourse/plugins/discourse-ruby/lib/discourse-markdown/md-ruby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.registerOptions((opts, siteSettings) => {
      opts.features["md-ruby"] = !!siteSettings.enable_markdown_ruby;
    });
    helper.registerPlugin(window.markdownitRuby);
  }
});